<template>
  <v-container
    v-ripple="{
      class:
        editable && !editMode ? 'dark--text text--lighten-1' : 'display-none',
    }"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <div>
            <h1 v-if="title" class="d-flex align-center text-h5 text-uppercase">
              {{ title }}
            </h1>
          </div>

          <div v-if="editable" class="text-caption text-uppercase">
            <v-icon x-small>mdi-calendar</v-icon>
            Última atualização:
            {{ formatDate(userData.updatedAt) }}
          </div>
        </v-col>
        <v-col v-if="editable">
          <v-btn
            :loading="loading"
            color="primary"
            block
            :disabled="editMode"
            @click="editMode = true"
          >
            <v-icon left dark>mdi-pencil</v-icon> Editar cadastro
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mb-4 mt-2" />
    <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="submit">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="email"
              disabled
              label="Email"
              dense
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="name"
              :rules="[required(name)]"
              :disabled="loading || (editable && !editMode)"
              label="Nome"
              required
              dense
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              data-lpignore="true"
              v-model="state"
              label="Em que estado você mora?"
              :items="states"
              :rules="[required(state)]"
              :disabled="loading || (editable && !editMode)"
              no-data-text="Estado não encontrado"
              required
              dense
              filled
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-autocomplete
              data-lpignore="true"
              :items="cities"
              v-model="city"
              label="Em que cidade você mora?"
              :rules="[required(city)]"
              :disabled="loading || (editable && !editMode)"
              :filter="filterObject"
              no-data-text="Cidade não encontrada"
              required
              dense
              filled
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="gender"
              label="Com qual gênero você se identifica?"
              class="mt-0"
              dense
              required
              :rules="[notNull(gender)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in genderList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-expand-transition>
            <v-col cols="12" v-if="gender === 'Outro'">
              <v-text-field
                v-model="otherGender"
                :rules="[required(otherGender)]"
                :disabled="loading || (editable && !editMode)"
                label="Especifique"
                required
                dense
                filled
              ></v-text-field
            ></v-col>
          </v-expand-transition>
          <v-col cols="12">
            <v-radio-group
              v-model="ethnicity"
              label="Qual sua cor, raça/etnia (conforme o IBGE)?"
              class="mt-0"
              dense
              required
              :rules="[notNull(ethnicity)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in ethnicityList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="age"
              label="Qual a sua idade?"
              class="mt-0"
              dense
              required
              :rules="[notNull(age)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in ageList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <!-- <v-col cols="12">
            <v-radio-group
              v-model="firstTimeVoting"
              label="Você votará pela primeira vez nas eleições de 2022?"
              class="mt-0"
              dense
              required
              :rules="[notNull(firstTimeVoting)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="Sim" :value="true"></v-radio>
              <v-radio label="Não" :value="false"></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col cols="12">
            <v-radio-group
              v-model="ocupation"
              label="Atualmente você"
              class="mt-0"
              dense
              required
              :rules="[notNull(ocupation)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in ocupationList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="isTeacher"
              label="Você é professor ou professora?"
              class="mt-0"
              dense
              required
              :rules="[notNull(isTeacher)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="Sim" :value="true"></v-radio>
              <v-radio label="Não" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-if="isTeacher === true">
            <v-radio-group
              v-model="teachingAt"
              label="Se você respondeu Sim, você:"
              class="mt-0"
              dense
              required
              :rules="[notNull(teachingAt)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in teachingAtList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="hasDisability"
              label="Você é pessoa com deficiência?"
              class="mt-0"
              dense
              required
              :rules="[notNull(hasDisability)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="Sim" :value="true"></v-radio>
              <v-radio label="Não" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-if="hasDisability === true">
            <v-radio-group
              v-model="disability"
              label="Se você respondeu Sim, informe o tipo de sua(s) deficiência(s)"
              class="mt-0"
              dense
              required
              :rules="[notNull(disability)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in disabilityList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="cameFrom"
              label="Como ficou sabendo da Escola de Mudadores?"
              class="mt-0"
              dense
              required
              :rules="[notNull(cameFrom)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio
                v-for="option in cameFromList"
                :key="option"
                :label="option"
                :value="option"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="humanRightsKnowledge"
              label="De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que sabe sobre direitos humanos?"
              class="mt-0"
              dense
              row
              required
              :rules="[notNull(humanRightsKnowledge)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="1" :value="1"></v-radio>
              <v-radio label="2" :value="2"></v-radio>
              <v-radio label="3" :value="3"></v-radio>
              <v-radio label="4" :value="4"></v-radio>
              <v-radio label="5" :value="5"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="enviromentKnowledge"
              label="De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que sabe sobre meio ambiente?"
              class="mt-0"
              dense
              row
              required
              :rules="[notNull(enviromentKnowledge)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="1" :value="1"></v-radio>
              <v-radio label="2" :value="2"></v-radio>
              <v-radio label="3" :value="3"></v-radio>
              <v-radio label="4" :value="4"></v-radio>
              <v-radio label="5" :value="5"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="violenceKnowledge"
              label="De 1 a 5, sendo 1 nada e 5 muito, quanto você sente que sabe sobre enfrentamento da violência?"
              class="mt-0"
              dense
              row
              required
              :rules="[notNull(violenceKnowledge)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="1" :value="1"></v-radio>
              <v-radio label="2" :value="2"></v-radio>
              <v-radio label="3" :value="3"></v-radio>
              <v-radio label="4" :value="4"></v-radio>
              <v-radio label="5" :value="5"></v-radio>
            </v-radio-group>
          </v-col>
          <!-- <v-col cols="12">
            <v-radio-group
              v-model="readyForElection"
              label="De 1 a 5, sendo 1 nada e 5 muito, quanto você se sente preparado para escolher um candidato nas eleições de 2022?"
              class="mt-0"
              dense
              row
              required
              :rules="[notNull(readyForElection)]"
              :disabled="loading || (editable && !editMode)"
            >
              <v-radio label="1" :value="1"></v-radio>
              <v-radio label="2" :value="2"></v-radio>
              <v-radio label="3" :value="3"></v-radio>
              <v-radio label="4" :value="4"></v-radio>
              <v-radio label="5" :value="5"></v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col
            cols="12"
            class="d-flex justify-center pt-0 pb-1 pl-1 pr-1 mb-4"
            v-if="showDisclaimer"
          >
            <div class="d-flex align-end">
              <v-checkbox
                v-model="disclaimer"
                :rules="[disclaimerRule(disclaimer)]"
                :disabled="loading || (editable && !editMode)"
                hide-details
                required
              >
              </v-checkbox>

              <v-dialog v-model="disclaimerDialog" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <span class="text-caption">
                    Eu aceito os<a
                      class="ml-1 font-weight-bold"
                      v-bind="attrs"
                      v-on="on"
                    >
                      TERMOS DE USO.
                    </a>
                  </span>
                </template>

                <v-card>
                  <v-container class="text-center">TERMOS DE USO </v-container>

                  <v-card-text>
                    <div>
                      Este Termo de Uso tem a finalidade de esclarecer como se
                      dá a utilização da plataforma de ensino à distância da
                      Escola de Mudadores. Ao aceitar estes Termos, você
                      concorda em não utilizar o sistema de maneira a prejudicar
                      ninguém, implicando abuso ou violação de direitos a um ou
                      mais indivíduos. O acesso à Trilha de Aprendizagem da
                      Escola de Mudadores está condicionada ao aceite dos
                      presentes Termos de Uso.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DO SITE E
                      PLATAFORMAS
                    </div>
                    <div>
                      Você precisará criar um cadastro para acessar a trilha de
                      aprendizagem, incluindo o fornecimento de um endereço de
                      e-mail válido e criação de senha, e será também
                      responsável por mantê-la atualizada e em sigilo. Embora o
                      conteúdo da trilha de aprendizado seja recomendável a
                      todos os públicos, caso você ainda não tenha completado 18
                      anos, te encorajamos a compartilhar a decisão de acessar e
                      navegar pela plataforma com os seus responsáveis legais,
                      inclusive convidando-os para se cadastrarem também.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      PERÍODO E CONDIÇOES DE ACESSO
                    </div>
                    <div>
                      O acesso é gratuito e a manutenção do acesso ao conteúdo
                      da trilha de aprendizagem se dará por tempo indeterminado,
                      mas estará sujeito a ajustes e atualizações procedidos
                      pela Escola de Mudadores.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      PERÍODO E CONDIÇOES DE ACESSO
                    </div>
                    <div>
                      O acesso é gratuito e a manutenção do acesso ao conteúdo
                      da trilha de aprendizagem se dará por tempo indeterminado,
                      mas estará sujeito a ajustes e atualizações procedidos
                      pela Escola de Mudadores.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      DIREITOS E DEVERES DO USUÁRIO
                    </div>
                    <div>
                      Ao aceitar os presentes Termos de Uso, o usuário concorda
                      em utilizar o nosso site e a plataforma de ensino à
                      distância de acordo com as leis vigentes no Brasil e se
                      declara ciente de que deverá cumprir com os itens abaixo:
                      <lu>
                        <li>
                          Fornecer e manter as suas informações pessoais sempre
                          completas, corretas, atualizadas e precisas;
                        </li>
                        <li>
                          Não publicar ou submeter qualquer informação
                          imprecisa, falsa ou incompleta e não se passar por
                          outra pessoa ou organização; e
                        </li>
                        <li>
                          Não utilizar quaisquer dispositivos, processos ou
                          mecanismos para acessar ou tentar acessar qualquer
                          conta ou dados pessoais de terceiros.
                        </li>
                      </lu>
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      LICENÇA DE USO
                    </div>
                    <div>
                      Os materiais escritos e audiovisuais da trilha de
                      aprendizagem estão licenciados com uma Licença Creative
                      Commons Atribuição 4.0 Internacional.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      LINKS E SITES DE TERCEIROS
                    </div>
                    <div>
                      O nosso conteúdo poderá apresentar links que direcionem o
                      usuário a websites de terceiros. Não seremos responsáveis
                      pelos conteúdos publicados em tais websites e tampouco
                      pela disponibilidade destes ou por seus termos de uso e
                      política de privacidade. Recomendamos a nossos usuários
                      que consultem os termos de uso e políticas de privacidade
                      aplicáveis.
                    </div>

                    <v-divider></v-divider>
                    <div class="text-uppercase font-weight-bold mt-2">
                      Política de Privacidade
                    </div>
                    <div>
                      Ao aceitar os Termos de Uso, o usuário aceita também a
                      nossa Política de Privacidade, que aborda como coletamos,
                      acervamos, protegemos e utilizamos as suas informações
                      pessoais. Esta Política de Privacidade se aplica a sua
                      experiência de cadastro e navegação pela Trilha de
                      Aprendizagem da Escola de Mudadores. Conheça abaixo os
                      termos desta Política.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      COLETA DE DADOS PESSOAIS
                    </div>
                    <div>
                      Para oferecer uma experiência relevante, adequada e segura
                      a todos, é necessário coletar, armazenar a analisar alguns
                      dados sobre os nossos usuários. A seguir, você poderá
                      conferir quais dados sobre você poderão ser coletados ao
                      utilizar nosso site e a plataforma de ensino à distância:
                      <lu>
                        <li>
                          Informações sobre o seu dispositivo, incluindo o seu
                          endereço de IP, dados geográficos, tipo e versão do
                          seu navegador e sistema operacional;
                        </li>
                        <li>
                          Informações sobre as visitas e uso do site ou
                          plataformas, incluindo a fonte de referência, duração
                          da visita, visualizações de páginas e caminhos de
                          navegação no site;
                        </li>
                        <li>
                          Informações que você concorda em inserir quando você
                          realiza qualquer tipo de cadastro ou inscrição
                          vinculados à Escola de Mudadores;
                        </li>
                        <li>
                          Informações que você insere quando envia uma mensagem
                          em nosso site;
                        </li>
                        <li>
                          Informações geradas durante a sua navegação em nosso
                          site, incluindo a frequência e as circunstâncias em
                          que tal(is) navegação(ões) ocorreram;
                        </li>
                        <li>
                          Preferências para receber publicidade personalizada,
                          não-personalizada ou genérica das novidades da Escola
                          de Mudadores;
                        </li>
                        <li>
                          Informações decorrentes de feedback e pesquisas;
                        </li>
                        <li>
                          Informações cuja legislação obrigue ou permite que
                          sejam coletadas e processadas, e que sejam necessárias
                          para a autenticação ou identificação do usuário ou
                          para a verificação da informação;
                        </li>
                        <li>
                          Qualquer outro dado pessoal que nos seja enviada por
                          meio dos nossos meios de comunicação.
                        </li>
                      </lu>
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      UTILIZAÇÃO DAS INFORMAÇÕES
                    </div>
                    <div>
                      As informações pessoais coletadas serão utilizadas para
                      assegurar o acesso, suporte e segurança de nossos usuários
                      ao acessar o nosso site e plataformas, incluindo:
                      <lu>
                        <li>Permitir o acesso à trilha de aprendizagem;</li>
                        <li>
                          Enviar comunicações informativas relativas à trilha de
                          aprendizagem e assuntos correlatos;
                        </li>
                        <li>
                          Enviar notificações por e-mail conforme solicitadas;
                        </li>
                        <li>
                          Responder perguntas ou comentários encaminhados;
                        </li>
                        <li>Manter o nosso site e plataformas seguros; e</li>
                        <li>
                          Verificar a conformidade com os termos e as condições
                          que regem o acesso a a nossa trilha de aprendizagem.
                        </li>
                      </lu>
                      Caso não deseje receber comunicações informativas, você
                      poderá solicitar a interrupção do envio deste tipo de
                      comunicação. As suas informações pessoais são cuidadas e
                      manejadas com responsabilidade e somente serão acessíveis
                      aos nossos administradores, procuradores, empregados e/ou
                      consultores autorizados que necessitem ter acesso a estes
                      dados, e sempre dentro dos limites previstos pela lei e
                      nesta Política de Privacidade.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      ARMAZENAMENTO DAS INFORMAÇÕES
                    </div>
                    <div>
                      Os dados coletados serão processados para o cumprimento de
                      nossas obrigações legais em relação ao armazenamento das
                      informações pessoais dos nossos usuários. Nos
                      comprometemos a tomar as devidas precauções técnicas e
                      organizacionais para evitar a perda, uso indevido ou
                      alteração de seus dados, que serão armazenados em
                      plataforma segura. Além disso, todas as informações
                      enviadas por meio de nosso site e plataformas serão
                      protegidas por criptografia.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      TRANSPARÊNCIA
                    </div>
                    <div>
                      Você poderá solicitar, a qualquer momento, ter acesso aos
                      seus dados pessoais que foram coletados e que se encontram
                      armazenados. Nos comprometemos a disponibilizar as suas
                      informações pessoais o mais rápido possível,
                      respeitando-se, em todo o caso, ao que dispõe a legislação
                      vigente.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      ATUALIZAÇÃO E CORREÇÃO DAS INFORMAÇÕES PESSOAIS
                    </div>
                    <div>
                      A correção, inclusão e atualização de seus dados pessoais
                      é um direito seu, e poderá ser realizada, a qualquer
                      momento diretamente na palatforma da ensino à distância da
                      Escola de Mudadores.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      PERÍODO DE RETENÇÃO DAS INFORMAÇÕES PESSOAIS
                    </div>
                    <div>
                      As informações coletadas serão eliminadas quando não forem
                      mais necessárias aos propósitos para os quais foram
                      coletadas, incluindo a necessidade de satisfazermos a
                      quaisquer requisitos legais. Ao final de tal ciclo, nos
                      comprometemos a descartar seus dados com segurança,
                      seguindo as exigências legais aplicáveis.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      EXCLUSÃO DAS INFORMAÇÕES PESSOAIS
                    </div>
                    <div>
                      Em cumprimento à legislação, manteremos os seus dados
                      seguros e armazenados. A solicitação de exclusão de parte
                      ou da totalidade das informações pessoais por nós
                      armazenadas será analisada sob este contexto e poderá ser
                      legalmente rejeitada, por exemplo, nos casos em que não
                      puder ser confirmada a identidade do solicitante. Se
                      houver dados pessoais que possam ser, desde logo,
                      excluídos, nos comprometemos a descartá-los no menor tempo
                      possível.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      DIVULGAÇÃO DAS INFORMAÇÕES PESSOAIS
                    </div>
                    <div>
                      Não divulgaremos suas informações pessoais de forma
                      excessiva, desnecessária ou em desconformidade com
                      determinações legais e normativas. Suas informações
                      poderão ser divulgadas em algumas situações específicas,
                      que incluem:
                      <lu>
                        <li>
                          Solicitação do usuário, pessoalmente ou por terceiro
                          expressamente autorizado;
                        </li>
                        <li>Em cumprimento da Lei;</li>
                        <li>
                          Em cumprimento de ordem emanada de Autoridade Pública
                          competente; e
                        </li>
                        <li>
                          Para estabelecer, exercer ou defender os nossos
                          direitos legais (incluindo o fornecimento de
                          informações a terceiros para fins de prevenção e
                          redução de fraudes).
                        </li>
                      </lu>
                      Nos casos de divulgação de informações para cumprimento da
                      Lei ou ordem emanada de Autoridade Pública competente,
                      revelaremos apenas os dados necessários para o estrito
                      cumprimento da disposição legal e/ou da ordem aplicável.
                      Ao escrever comentários ou avaliações sobre a nossa trilha
                      de aprendizagem, você concorda que estas informações,
                      juntamente com nome e foto de perfil cadastrados na
                      plataforma, podem ser divulgados e utilizados pela Escola
                      de Mudadores para fins de promover a trilha de
                      aprendizagem. Deste modo, ao realizar comentários ou
                      avaliações, é concedida uma autorização gratuita e dentro
                      dos limites da legislação, para divulgação de imagem e do
                      conteúdo inserido para qualquer material publicitário ou
                      forma de comunicação.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      CAPACIDADE CIVIL
                    </div>
                    <div>
                      Não exigimos o fornecimento de informações sobre a idade
                      ou a data de nascimento dos usuários e deste modo não nos
                      responsabilizamos pelo controle de acesso por menores de
                      idade sem o consentimento de seu(s) representante(s)
                      legal(is). A despeito de o conteúdo da trilha de
                      aprendizagem ser recomendado para todos os públicos,
                      recomendamos que menores de idade naveguem pela trilha com
                      o conhecimento e consentimento de seus cuidadores. Nós não
                      coletamos ou compartilhamos conscientemente nenhuma
                      informação de pessoas que não possuam capacidade civil,
                      caso isto ocorra, os dados serão imediatamente
                      descartados, sem notificação.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      SEGURANÇA
                    </div>
                    <div>
                      Nós tomamos todas as precauções para proteger as
                      informações pessoais dos usuários sob nosso controle.
                      Contudo, a transmissão de informações via internet pode
                      ser insegura, na medida em que trafega em diversos
                      provedores de serviços. Desse modo, você reconhece não ser
                      possível garantir a integralidade dos dados enviados via
                      internet. Pensando nisso, é importante que você proteja o
                      seu login, senha, computadores, dispositivos e aplicativos
                      de acesso não-autorizado.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      AVISO DE COOKIES
                    </div>
                    <div>
                      Podemos utilizar cookies em nosso site. Um cookie é um
                      arquivo que contém um identificador (uma sequência de
                      letras e números) enviados por um servidor da web para um
                      navegador da web e armazenados pelo navegador. O
                      identificador é então enviado de volta ao servidor sempre
                      que o navegador solicita uma página do servidor. A
                      utilização de cookies visa, principalmente: (i).
                      reconhecer um computador quando um usuário visita o nosso
                      site; (ii). acompanhar os usuários enquanto navegam no
                      nosso site; (iii). melhorar a navegabilidade do nosso
                      site; (iii). analisar o uso do nosso site; (iv).
                      administrar o nosso site; (v). evitar fraudes e melhorar a
                      segurança do nosso site; e (vi). indicar possíveis sites
                      que possam ser de particular interesse para nossos
                      usuários. Os cookies normalmente não contêm nenhuma
                      informação que possa te identificar pessoalmente, mas as
                      informações pessoais que armazenamos sobre os nossos
                      usuários podem estar vinculadas às informações armazenadas
                      e obtidas através de cookies.
                    </div>
                    <div class="text-uppercase font-weight-bold mt-2">
                      ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE
                    </div>
                    <div>
                      Realizaremos alterações nesta Política de sempre que
                      considerado necessário. Em caso de qualquer alteração
                      substancial, você será informado via e-mail, notificação
                      push ou através do sistema de mensagens privadas prevista
                      em nosso site e plataforma. USUÁRIOS INTERNACIONAIS O
                      servidor está hospedado na República Federativa do Brasil.
                      Ao acessar os nossos serviços de qualquer outro país ou
                      região com leis que regem a coleta de dados pessoais, uso
                      e divulgação, que diferem das leis em vigor na República
                      Federativa do Brasil, você expressamente concorda que será
                      considerada a legislação brasileira para a segurança de
                      suas informações pessoais.
                    </div>

                    <v-divider></v-divider>
                    <div class="text-uppercase font-weight-bold mt-2">
                      DÚVIDAS
                    </div>
                    <div>
                      Em caso de dúvidas, consulte nossa página de Perguntas
                      Frequentes e caso não encontre o que busca, estamos a
                      postos para te ajudar. Entre em contato enviando um e-mail
                      para o endereço:
                      <a href="mailto:suporte@escolademudadores.app"
                        >suporte@escolademudadores.app</a
                      >
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="disclaimerDialog = false"
                    >
                      fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!editable">
        <v-row justify="center">
          <v-col cols="12" class="d-flex justify-center pa-1">
            <div>
              <v-btn
                large
                block
                :loading="loading"
                color="primary"
                @click="submit"
              >
                <v-icon left dark>mdi-content-save-outline</v-icon>
                Finalizar cadastro
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pa-1">
            <div>
              <v-btn
                block
                :loading="loading"
                color="primary"
                text
                @click="signOut"
              >
                <v-icon left dark>mdi-exit-to-app</v-icon>
                Sair
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-btn
              :loading="loading"
              color="success"
              class="dark--text"
              block
              :disabled="!editMode"
              @click="submit"
            >
              <v-icon dark left>mdi-content-save-outline</v-icon>Salvar cadastro
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              :loading="loading"
              color="error"
              text
              class="mr-4"
              @click="
                () => {
                  reset();
                  editMode = false;
                }
              "
              :disabled="!editMode"
            >
              <v-icon dark left>mdi-cancel</v-icon>Cancelar edição
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-snackbar color="success" text v-model="success">
        Cadastro atualizado com sucesso.
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="success = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar color="error" text v-model="error">
        {{ errorMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn icon large v-bind="attrs" @click="error = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>
<script>
import { signOut } from "../../plugins/auth";
import { states, cities } from "estados-cidades";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["show-disclaimer", "editable", "title"],
  data: () => ({
    valid: false,
    name: null,
    email: null,
    state: null,
    city: null,
    gender: null,
    otherGender: null,
    ethnicity: null,
    age: null,
    firstTimeVoting: null,
    ocupation: null,
    isTeacher: null,
    teachingAt: null,
    hasDisability: null,
    disability: null,
    cameFrom: null,
    humanRightsKnowledge: null,
    enviromentKnowledge: null,
    violenceKnowledge: null,
    readyForElection: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false,
    disclaimer: false,
    disclaimerDialog: false,
    editMode: false,
    genderList: ["Feminino", "Masculino", "Prefiro não dizer", "Outro"],
    ethnicityList: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Outra"],
    ageList: [
      "Menor que 16 anos",
      "Entre 16 e 18 anos",
      "Entre 19 e 24 anos",
      "Entre 25 e 30 anos",
      "Entre 30 e 45 anos",
      "Entre 45 e 60 anos",
      "Acima de 60 anos",
    ],
    ocupationList: [
      "Estuda em escola ou universidade pública",
      "Estuda em escola ou universidade particular",
      "Trabalha e estuda em escola ou universidade pública",
      "Trabalha e estuda em escola ou universidade particular",
      "Não estuda, só trabalha",
      "Não estuda, nem trabalha",
      "Outro",
    ],
    teachingAtList: [
      "dá aulas na rede pública",
      "dá aulas em escolas particulares",
      "outro",
    ],
    disabilityList: [
      "Deficiência Visual",
      "Deficiência Auditiva",
      "Deficiência Física",
      "Deficiência Mental/Intelectual",
      "Transtorno do Espectro Autista",
      "Deficiência Múltipla",
      "Outra(s)",
    ],
    cameFromList: [
      "Redes sociais",
      "Whatsapp",
      "TV",
      "Portais de notícias",
      "Secretaria de educação",
      "Outro",
    ],
  }),
  mounted() {
    this.reset();
  },
  computed: {
    ...mapGetters(["userData"]),
    form: function () {
      return {
        email: this.email,
        name: this.name,
        name_casefold: this.name.toLowerCase(),
        state: this.state,
        state_casefold: this.state.toLowerCase(),
        city: this.city,
        city_casefold: this.city.toLowerCase(),
        gender: this.gender,
        gender_casefold: this.gender.toLowerCase(),
        otherGender: this.gender === "Outro" ? this.otherGender : "",
        otherGender_casefold: this.otherGender
          ? this.otherGender.toLowerCase()
          : "",
        ethnicity: this.ethnicity,
        ethnicity_casefold: this.ethnicity.toLowerCase(),
        age: this.age,
        age_casefold: this.age.toLowerCase(),
        // firstTimeVoting: this.firstTimeVoting,
        ocupation: this.ocupation,
        ocupation_casefold: this.ocupation.toLowerCase(),
        isTeacher: this.isTeacher,
        teachingAt: this.isTeacher ? this.teachingAt : "",
        teachingAt_casefold: this.teachingAt
          ? this.teachingAt.toLowerCase()
          : "",
        hasDisability: this.hasDisability,
        disability: this.hasDisability ? this.disability : "",
        disability_casefold: this.disability
          ? this.disability.toLowerCase()
          : "",
        cameFrom: this.cameFrom,
        cameFrom_casefold: this.cameFrom.toLowerCase(),
        humanRightsKnowledge: this.humanRightsKnowledge,
        enviromentKnowledge: this.enviromentKnowledge,
        violenceKnowledge: this.violenceKnowledge,
        // readyForElection: this.readyForElection,
        hasCompleteProfile: this.valid,
      };
    },
    states: () => states(),
    cities: function () {
      return this.state ? cities(this.state) : [];
    },
  },
  methods: {
    ...mapActions(["updateProfile"]),
    submit() {
      this.validate();
      if (!this.valid || this.loading) return;
      this.loading = true;
      this.errorMessage = null;
      this.error = false;
      this.updateProfile({
        form: this.form,
        successCallback: this.successCallback,
        errorCallback: this.errorCallback,
      });
    },
    successCallback() {
      this.success = true;
      this.loading = false;
      this.$emit("success");
      if (this.editable) this.editMode = false;
      this.reset();
    },
    errorCallback(error) {
      this.loading = false;
      this.errorMessage = error;
      this.error = true;
    },
    required(v) {
      return !!v || "Campo obrigatório";
    },
    notNull(v) {
      return v != null || "Campo obrigatório";
    },
    minLength(v, min) {
      return (
        !v ||
        v.length >= min ||
        "Campo deve ter no mínimo " + min + " caracteres"
      );
    },
    emailRule(v) {
      return /.+@.+/.test(v) || "Email inválido";
    },
    disclaimerRule(v) {
      return (
        v == true || "Você precisa aceitar os termos para realizar o cadastro"
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.email = this.userData.email;
      this.name = this.userData.name;
      this.email = this.userData.email;
      this.state = this.userData.state;
      this.city = this.userData.city;
      this.gender = this.userData.gender;
      this.otherGender = this.userData.otherGender;
      this.ethnicity = this.userData.ethnicity;
      this.age = this.userData.age;
      // this.firstTimeVoting = this.userData.firstTimeVoting;
      this.ocupation = this.userData.ocupation;
      this.isTeacher = this.userData.isTeacher;
      this.teachingAt = this.userData.teachingAt;
      this.hasDisability = this.userData.hasDisability;
      this.disability = this.userData.disability;
      this.cameFrom = this.userData.cameFrom;
      this.humanRightsKnowledge = this.userData.humanRightsKnowledge;
      this.enviromentKnowledge = this.userData.enviromentKnowledge;
      this.violenceKnowledge = this.userData.violenceKnowledge;
      // this.readyForElection = this.userData.readyForElection;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    filterObject(item, queryText) {
      return (
        this.removeDiacritics(item).indexOf(this.removeDiacritics(queryText)) >
        -1
      );
    },
    removeDiacritics(str) {
      return str
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    async signOut() {
      await signOut();
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>
<style>
.display-none {
  display: none;
}
.v-input--radio-group__input legend {
  width: 100%;
}
</style>
